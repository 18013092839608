.react-tel-input input{
	border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
	width: 100% !important;
	height: unset !important;
}

.react-tel-input .flag-dropdown {
	border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
	border-radius: 0.375rem 0 0 0.375rem !important;
}

.form-control {
	border-bottom-right-radius: 0.375rem !important;
	border-top-right-radius: 0.375rem !important;
}

.select-control {
	flex: 1 0;
	flex-basis: 0%;
	min-width: 0;
	width: 1%;
}

.select-control > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
	border-bottom-right-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
	border: 1px solid var(--cui-input-border-color, #b1b7c1);
}

.lg-input {
  width: 300px;
  max-width: 300px;
  text-align: center;
}

.md-input {
  width: 170px;
  max-width: 170px;
}

.sm-input {
  width: 80px;
  max-width: 80px;
  text-align: center;
}

.center {
	position: absolute;
	top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
	margin-bottom: 0px;
}

.btn {
	flex-shrink: 0;
}

.table > tbody {
	vertical-align: middle;
}

.switch-input-group {
  display: flex;
	align-items: center;
  justify-content: center;
	width: 60px;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  border-radius: 0.25rem;
}

.modal-backdrop:not(:last-of-type){
	z-index: 1055;
}

